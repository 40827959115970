<template>
  <div class="home_div">
      <div id="container">
      </div>
      <button class="Navigation" @click="handleClick">开始导航</button>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: "Mapview",
  mounted() {
      this.initAMap();
  },
  methods: {
      initAMap() {
          AMapLoader.load({
              key: 'c7a05075fca1d44c0dc7b3dce587760c',
              version: "2.0",
              plugins: ['AMap.ToolBar', 'AMap.Scale','AMap.MapType'],
              AMapUI: {
                  version: "1.1",
                  plugins: [],

              },
              Loca: {
                  version: "2.0"
              },
          }).then((AMap) => {
              this.map = new AMap.Map("rightbox", {
                  viewMode: "3D",
                  zoom: 17,
                  zooms: [2, 22],
                  center: [113.929102,22.540994],
              });
              this.map.addControl(new AMap.ToolBar())
              this.map.addControl(new AMap.Scale())
              this.map.addControl(new AMap.MapType())
              let mark = new AMap.Marker({
                position: new AMap.LngLat(113.929102,22.540994),
                title:'深圳市晶准通信技术有限公司'
              })
              this.map.add(mark)
          })
      },
      handleClick(){
        window.location.href='https://uri.amap.com/marker?position=113.929102,22.540994&name=深圳市晶准通信技术有限公司'
      }
  },
  beforeDestroy(){
    this.map.destroy();
    this.map = null;
    this.mapModule = null;
  }


}
</script>
<style  scoped>
.Navigation{
    position: absolute;
    z-index: 999;
    top: 130px;
    right: 20px;
    background: #FFFFFF;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 3px rgb(0 0 0 / 50%);
    padding: 5px 20.5px;
    font-size:12px;
}
</style>